<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 26 26" fill="#5B5B5B">
              <path d="M5 0C3.898438 0 3 0.898438 3 2L3 21C3 22.101563 3.898438 23 5 23L10.3125 23C9.613281 22.398438 9 21.800781 8.5 21L5 21L5 2L20 2L20 7.90625C20.699219 8.304688 21.398438 8.710938 22 9.3125L22 2C22 0.898438 21.101563 0 20 0 Z M 8 5L8 7L17 7L17 5 Z M 16 8C14.597656 8 13.273438 8.363281 12.125 9L8 9L8 11L9.78125 11C8.679688 12.371094 8 14.113281 8 16C8 20.40625 11.59375 24 16 24C17.585938 24 19.066406 23.519531 20.3125 22.71875L23.59375 26L25 24.59375L21.84375 21.4375C23.171875 20.007813 24 18.097656 24 16C24 11.59375 20.40625 8 16 8 Z M 16 10C19.324219 10 22 12.675781 22 16C22 19.324219 19.324219 22 16 22C12.675781 22 10 19.324219 10 16C10 12.675781 12.675781 10 16 10 Z M 13 12C12 12.699219 11.292969 13.800781 11.09375 15L19 15L19 12 Z M 11.09375 17C11.292969 18.199219 12.101563 19.300781 13 20L19 20L19 17Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Explanation</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-between flex-wrap justify-xs-center">
          <div class="flex justify-between flex-wrap justify-xs-center">
            <SelectComponent
            :items="types"
            :placeholder="false"
            @getObject="onChangeQuestionComponent"
            return-type="object"
            class="w-64 m-1" />
          <SelectComponent
            :items="questionTypes"
            @getObject="onChangeQuestionType"
            :placeholder="false"
            return-type="object"
            class="w-64 m-1" />
          </div>
            <SearchBox
              v-model="keyword"
              placeholder="Search"
              class="p-2 w-64 m-2"
              @submit="submitSearchKeyword"
            />
        </div>
        <md-divider></md-divider>
        <md-table hover-action class="perent_table" v-model="explanation" md-sort="name" md-model-id="some_id" md-sort-order="asc" md-card md-fixed-header>
          <md-table-row slot="md-table-row" slot-scope="{ item, index }">
            <div style="display: inline-table;width: 100%;" class="perent_table_tr">            
              <md-table-cell width="100px" md-label="SL" md-sort-by="id">{{ index + 1 }}</md-table-cell>
              <md-table-cell class="pl-1" width="151px" md-label="Q_ID" md-sort-by="name">{{ item.q_id }}</md-table-cell>
              <md-table-cell class="pl-1" width="158px" md-label="TYPE">{{ item.question_type.short_title }}</md-table-cell>
              <md-table-cell class="pl-0" width="287px" md-label="INDEX" md-sort-by="index">{{ item.index }}</md-table-cell>
              <md-table-cell width="100px" md-label="NO OF EXPLANTION" md-sort-by="title">{{ item.explanations.length || 0}}</md-table-cell>
              <md-table-cell width="144px" md-label="STATUS"  v-bind:class="item.active === 1  ? 'pl-3 text-green' : 'pl-3 text-red'">{{ item.active === 1 ? 'active' : 'Inactive' }}</md-table-cell>
              <md-table-cell md-label="" style="width: 10px"><div class="text-primary text-xl pointer" @click="collapses(item.id)">{{ item.id === show ? '−' : '+' }}</div></md-table-cell>
            </div> 
            <Table class="child_table" :items="(item.explanations)" hover-action v-if="item.id === show">
              <template slot="row" slot-scope="{ item }">
                <!-- <md-table-cell md-label="SL">{{ item.sl }}</md-table-cell> -->
                <md-table-cell md-sort-by="promptId" md-label="EXP_ID" class="text-victoria">{{ item.exp_id }}</md-table-cell>
                <md-table-cell class="details" md-sort-by="details" md-label="EXPLANATION DETAILS"><div class="details" v-html="item.title"></div></md-table-cell>
                <md-table-cell md-sort-by="by" md-label="CREATED BY" class="text-victoria">{{ item.created_by }}</md-table-cell>
                <md-table-cell md-sort-by="createdAt" md-label="CREATED AT" class="text-victoria">{{ item.created_at }}</md-table-cell>
                <md-table-cell md-sort-by="status"  md-label="STATUS" v-bind:class="item.is_default === 1  ? 'text-green' : 'text-red'">{{ (item.is_default === 1) ? 'Active': 'Inactive' }}</md-table-cell>
                <md-table-cell >
                  <span class="flex action">
                  <span
                    class="rounded px-1 text-white text-base mx-1 flex align-center"
                    :class="[toLower((item.is_default == 1) ? 'Active': 'Inactive') == 'active' ? 'bg-victoria' : 'bg-danger']"
                    @click="onChangeStatus(item)">{{ (item.is_default == 1) ? 'Active': 'Inactive' }}
                  </span>
                  <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails(item.id)">visibility</md-icon>
                  <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit(item)">edit</md-icon>
                  <md-icon 
                    class="rounded-full p-1 text-white text-base mx-1" 
                    :class="item.is_archived == 0 ? 'bg-green' : 'bg-danger'"
                    @click.native="onArchived(item)">archive</md-icon>
                  </span>
                </md-table-cell>
              </template>
            </Table>
          </md-table-row>
          
        </md-table>
       
     
        <div @click="onShowCreate" style="position: absolute; bottom: -10px; right: -10px;z-index: 11;"
          class="bg-victoria pointer center w-16 h-16 rounded-full">
          <md-icon class="text-6xl text-white" style="font-size: 36px !important;">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
        <Paginate
           :start="meta.current_page"
            :end="meta.last_page"
            :total="meta.last_page"
            :limit="meta.per_page"
            @on-start="onStart"
            @on-end="onEnd"
        />
      </div>
    <Dialog>
      <div class="p-8">
        <component :title="title" :type_name="type_name" :content="content" :is="component"></component>
      </div>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox,
    SelectComponent
    } from "@/components";

// import ExplanationCreate from "@/components/molecule/question-and-test-management/material/ExplanationCreate";
import ExplanationCreate from "@/components/molecule/question-and-test-management/material/ExplanationCreate";
import ExplanationShow from "@/components/molecule/question-and-test-management/material/ExplanationShow";
import ExplanationEdit from "@/components/molecule/question-and-test-management/material/ExplanationEdit";
import cms from "@/data/cms";
import { mapMutations, mapActions, mapGetters } from "vuex";
import { paginate } from "@/mixins/paginate";
import { query } from "@/utils/queryString";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        ExplanationCreate,
        ExplanationShow,
        ExplanationEdit,
        SelectComponent
  },
  data() {
      return {
          currentIndex: 0,
          questionSection: '',
          type_name: {
            name:'explanation',
            id: 'e_id'
          },
          short_title: '',
          content: '',
          title: '',
          component: 'ExplanationCreate',
          types: cms.types,
          questionTypes: cms.Q_Types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          paginate: {
            start: 1,
            end: 3,
            total: 100,
            limit: 2
          },
          show : 0
      }
  },

  computed: {
    ...mapGetters({
      explanation: "explanation/getExplanations",
      meta: "explanation/getExplanationQuestionListMetaMeta",
      getQuestionTypes: "question/getQuestionTypes",
      getSelectedQuestionType: "question/getSelectedQuestionType"
    })
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actQuestionListExplanation(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actQuestionListExplanation(`?${query.get()}`);
        }
    }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
      setExplanationShow: "explanation/setExplanationShow",
    }),
    ...mapActions({
      actQuestionListExplanation: "explanation/actQuestionListExplanation",
      actShowExplanation: "explanation/actShowExplanation",
      actChangeStatusExplanation: "explanation/actChangeStatusExplanation",
      actChangeDetaultStatusExplanation: "explanation/actChangeDetaultStatusExplanation",
      actGetQuestionTypes: "question/actGetQuestionTypes",
    }),
    onChangeQuestionComponent(item) {
      query.set('section', item.slug)
      this.actQuestionListExplanation(`?${query.get()}`);
      this.actGetQuestionTypes(`?section=${item.slug}`)
    },
    onChangeQuestionType(item){
      console.log(item)
      query.set('section', item.slug)
      this.actQuestionListExplanation(`?${query.get()}`);
    },
    collapses(id){
      this.show = id;
      console.log(this.show)
    },
    onShowDetails(explanationId) {
      this.component = 'ExplanationShow';
      this.dialog(true);
      this.actShowExplanation(explanationId)
    },
    onShowCreate() {
      this.component = 'ExplanationCreate';
      this.dialog(true);
    },
    onShowEdit(item) {
      this.component = 'ExplanationEdit';
      this.dialog(true);
      this.actShowExplanation(item.id);
    },
    onArchived(item) {
      console.log('data_0111',item)
      this.actChangeStatusExplanation({
        explanationId: item.id,
        payload: {
          active: item.active,
          is_archived: (item.is_archived != 1),
        }
      }).then(message => {
        this.setShowSnackbar(message);
        this.actQuestionListExplanation(``);
      })
    },
    onChangeStatus(item) {
      console.log('data_0111',item)

      this.actChangeDetaultStatusExplanation({
        explanationId: item.id,
        payload: {
          question_id: this.show,
          active: item.active,
          questions: { [this.show] : { is_default: item.is_default != 1  } }
        }
      }).then(message => {
        this.setShowSnackbar(message);
        this.actQuestionListExplanation(``);
      })
    },
    submitSearchKeyword() {
      this.actQuestionListExplanation(`?${query.set('search', this.keyword)}`);
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuestionListExplanation(`?${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuestionListExplanation(`?${query.get()}`);
    },
  },
  created() {
    this.actQuestionListExplanation(``); // pass emtpy query value for getting all data
    this.actGetQuestionTypes(`?section=`);
  }
}
</script>

<style>
.perent_table .md-table-head-label {
    font-style: italic;
    color: #000000;
    font-size: 14px;
}
.perent_table .md-table-content.md-scrollbar{
 max-height: 100% !important;
}
.perent_table_tr .md-table-cell-container {
    padding: 6px 32px 6px 24px;
    /* text-align: center; */
    font-size: 14px;
    font-weight: 600;
    margin-top: 4px;
}
.child_table{
  /* transform: scale(.97); */
  margin-left: 40px;
}
.child_table .md-table.md-theme-default .md-table-row:hover:not(.md-header-row) .md-table-cell{
  background: #9c27b021 !important;
}
.child_table .md-table-cell-container{
  font-size: 13px;
  color: #000;
  font-weight: 600;
}
.pl-1 .md-table-cell-container {
    padding-left: 10px !important;
}
.pl-0 .md-table-cell-container {
    padding-left: 0px !important;
}
.perent_table .child_table .md-table-head-label {
    font-style: inherit;
    color: #969494;
    font-size: 13px;
}
.perent_table .child_table .md-table-head-container {
    padding: 4px 0;
    height: auto;
}
</style>