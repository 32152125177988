<template>
<div class="p-6" style="min-width: 700px">
   <DialogTitle v-if="!justShow" label="Study Guide" align="center" />
  <div class="archive mt-6">
    <label class="text-uppercase font-bold ml-4 text-lg">Sample Answer <small class="text-victoria w-75p font-bold text-lg line-height-27">{{SampleAnswerShow.question_type_section}}</small></label>
      <div  class="m-2 md-layout-item md-layout md-gutter mt-2 md-size-100 z-9 align-start">
        <div class="md-layout-item md-size-100 mt-0 pt-0">
          <div class="p-2 b-d m-2 b-d-c br-3">
            <div class="my-2">
              <p class="text-uppercase mb-0 font-bold">THE Sample Answer</p>
              <div  class="text-base line-height-27 rounded pb-2" v-html="SampleAnswerShow.title"></div>
              <div v-if="SampleAnswerShow.question_type_section === 'SPEAKING'" class="flex justify-between align-center">
                <div class="mt-2 md-size-100">
                    <span>
                        <button type="button" :class="isActive? 'active': ''" v-on:click="genderHandle('male')"  class="pl-3 pr-3 initial_color">Male</button>
                        <button type="button" :class="isActive? '': 'active'" v-on:click="genderHandle('female')"  class="pl-3 pr-3 initial_color">Female</button>
                    </span>
                    <div class="archive_file_body" v-if="SampleAnswerShow.resources != null" style="zoom:.9">
                        <span v-if="isActive">
                            <Audio :src="SampleAnswerShow.resources[0].file_path"/>
                        </span>
                        <span v-if="!isActive">
                            <Audio :src="SampleAnswerShow.resources[1].file_path"/>
                        </span>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div v-if="!justShow">
    <div class="flex justify-center">
      <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
      <md-button class="outline-gray-400 bg-victoria text-white text-uppercase rounded">Go to Edit</md-button>
    </div>
  </div>
</div>
</template>

<script>
//import VideoThumbnail from "@/components/atom/VideoThumbnail";
import Audio from '@/components/molecule/question/audio.vue';
import { DialogTitle } from "@/components";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Todo-List",
  components: {
   // VideoThumbnail,
    DialogTitle ,
   Audio
  },
  data() {
    return {
       active: false,
       isActive: true,
    }
  },
  props: {
        justShow: {
            type: Boolean,
            default: () => false
        }
    },
    computed: {
        ...mapGetters({
            SampleAnswerShow: "sampleAnswer/getSampleAnswerShow"
        }),
    },
  methods: {
    ...mapMutations({
            dialog: "setShowDialog"
    }),
    genderHandle(element) {
        console.log(element)
        if (element == 'male') {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
    }
  },
  created() {
   
  }
};
</script>